.graficas-prueba{
    margin: 0 auto;
    text-align: center;
    width: 90%;
}
.graficos{
    margin: 0 auto;
    width: 100%;
}
.botones-rchar{
  padding-top: 20px;
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 14% 14% 15% 15%;
  grid-template-areas:
  "a b c d";
  grid-gap:10px;
}
.option1,.option2, .option3{
    font-size: 20px;
}
.option1{
    grid-area: a;
}
.option2{
    grid-area: b;
}
.option3{
    grid-area: c;
}
.opcions_table_graphic{
    width: 90%;
    margin: 0 auto;
    text-align: left;
}
.button1pp{
    margin-right: 40px;
}
.button1pp, .button2pp{
    border-radius: 15px;
    font-size: 20px;
    width: 150px;
}