.containeritems{
  padding-left: 20px;
  width: 100%;
  height: 30%;
}
.navegacion{
  padding-left: 0;
  margin-left: 0;
}
.navegacion ol{
  background-color: white;
  font-size: 15px;
}


.TableConsultarPaquetes{
  margin: 0 auto;
  width: 95% !important;
}
.TableAddSale{
  margin: 0 auto;
  width: 95% !important;
}

.css-b62m3t-container div{
  color: black !important;
}
.contentbotons{
  float: left;
  width: 80%;
  text-align: left;
}

#countsale{
  float: right;
  font-size: 18px;
}


.package_info{
  float: left;
  font-size: 15px;
}
.package_info p{  
  float: left;
  padding-left: 20px;
  padding-top: 10px;
}
.package_info p #getitem{
  font-weight: bold;
}



.TableAddSale tr td{
  white-space: normal !important;
}

/* CUERPO TABLA */

.TableAddSale td:nth-child(1),
.TableAddSale td:nth-child(2),
.TableAddSale td:nth-child(3){
  text-align: left;
}
.TableAddSale td:nth-child(4){
  text-align: center;
}
.TableAddSale td:nth-child(5){
  text-align: left;
}
.TableAddSale td:nth-child(6){
  text-align: center;
}

.TableAddSale td:nth-child(7){
  text-align: center;
}

.TableAddSale td:nth-child(8){
  text-align: right;
}
.datospackage{
  width: 100%;
  height: 40px;
  padding-left: 35%;
}
/* tabla consultar paquetes */

.TableConsultarPaquetes td:nth-child(1) img{
  width: 25px  !important;
  height: 25px !important;
}
.TableConsultarPaquetes td:nth-child(2){
  text-align: left;
}
.TableConsultarPaquetes td:nth-child(1){
  width: 12%;
}
.TableConsultarPaquetes .gtTqsE {
  display: block !important;
}

.TableConsultarPaquetes td:nth-child(7){
  text-align: center;
}
.TableConsultarPaquetes td:nth-child(5),
.TableConsultarPaquetes td:nth-child(9),
.TableConsultarPaquetes td:nth-child(10){
  text-align: right;
}
/*boton para regresar*/
.buttonback{
  height: 70px;
}
 
#buttonback {
  font-size: 13px;
  width: 100px;
  float: left;
  font-weight: bold;
}

.regresarbutton{
  float: left;
  cursor: pointer;
}



/* RESPONSE TABLA */

@media only screen and (max-width: 1390px) {
  .TableAddSale  {
      font-size: 13px;
      width: 97%;
  }
  .TableConsultarPaquetes{
    font-size: 13px;
      width: 97%;
  }
  
  thead tr div #custom-filter{
    font-size: 13px !important;
  }
  #buttonpackageb{
    font-size: 13px;
    font-weight: bold;
  }

}

@media only screen and (max-width: 1320px) {
  .TableAddSale  {
      font-size: 12px;
      width: 97%;
  }
  .TableConsultarPaquetes{
    font-size: 12px;
    width: 97%;
  }
  #buttonpackageb{
    font-size: 12px;
    font-weight: bold;
  }
  thead tr div #custom-filter{
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1059px) {
    .TableAddSale  {
        font-size: 9.5px;
        width: 98%;
    }
    .TableConsultarPaquetes{
      font-size: 9.5px;
        width: 98%;
    }
    .TableConsultarPaquetes td:nth-child(1){
      width: 10%;
    }
    #buttonpackageb{
      font-size: 9.5px;
      font-weight: bold;
    }
    thead tr div #custom-filter{
      font-size: 9.5px !important;
    }
}

@media only screen and (max-width: 890px) {
    .TableAddSale  {
        font-size: 8.5px;
    }
    .TableAddSale{
        width: 100%;
    }
    .TableConsultarPaquetes{
      font-size: 8.5px;
        width: 100%;
    }    
    #buttonpackageb{
      font-size: 8.5px;
      font-weight: bold;
    }
    thead tr div #custom-filter{
      font-size: 8.5px !important;
    }
}

/*estilos del input paper*/
.paper{
  width: 90px;
}
.NoInput{
  width: 13px!important;
} 


.InputVacio{
  background-color: red;
  color: white;
  font-size: 15px;
  margin-bottom: 3px;
}


.contanerFIltersTableSelectBonus {
  margin: 0 auto;
  width: 90%;
  display: grid;
  gap: 10px;
}
.contanerFIltersTableSelectBonus {
  grid-template-areas:
    "cp1 cp2  cp3 cp4 cp5 cp6 cp7";
}


.form-check{
  width: 100%;
}
.form-check label{
  color: black;
}
.form-check input{
  text-align: left;
  align-items: left;
}