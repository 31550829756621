div .container_search {
  display: grid;
  grid-template-areas: "icono barra";
  grid-template-columns: 10% 90%;
  grid-template-rows: 100%;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  padding: 0%;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

div > .container_search > .icon_search {
  display: grid;
  grid-area: icono;
  margin: 1px;
  font-size: 22px;
  justify-self: center;
}

div > .container_search > .barra{
  display: grid;
  grid-area: barra;
  width: 100%;
  height: 100%;
  right: 0%;
  border: none;
  border-radius: 0px 1px 1px 0px;
  margin: 0%;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-decoration:solid;
  font-size: 16px;
}

div > .container_search > input::placeholder{
  color:rgb(29, 27, 27);
  opacity:unset;
}

div > .container_search > .result{
  background-color: black;
  overflow-y: scroll;
}

div > .container_search  .result .section{
  color:black;
  left: 40px;
  width: max-content;
  margin: 1px;
  border-bottom: 2px solid gray;
}

@media only screen and (max-width: 1200px) {
  div > .container_search > .icon_search {
    display: grid;
    grid-area: icono;
    margin: 1px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  div > .container_search > .icon_search {
    display: grid;
    grid-area: icono;
    margin: 1px;
    font-size: 17px;
  }
}

@media only screen and (max-width: 950px) {
  div > .container_search > .icon_search {
    display: grid;
    grid-area: icono;
    margin: 1px;
    font-size: 14px;
  }
}