

table {
  border-spacing: 0;
  border: 1px solid #d1cccc;
  
}


tr:last-child td {
  border-bottom: 0;
}


th
td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #d1cccc;
  border-right: 1px solid #d1cccc;
}

th:last-child,
td:last-child {
  border-right: 0;
  
}
td:nth-child(2),
td:nth-child(3),
td:nth-child(4),
td:nth-child(6),
td:nth-child(11){
  text-align: center;
}

.tableConsultas td:nth-child(12),
.tableConsultas td:nth-child(13){
  text-align: center;

}

td:nth-child(7),
td:nth-child(8),
td:nth-child(9),
td:nth-child(10){
  text-align: right;
}
tr:nth-child(even) {
  background: #fbceb5;
  color: #000000;
}

.pagination {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
}

.page-controller {
  width: 20px;
  height: 17px;
  display: flex;
}

select {
  border: 1px solid #d1cccc;
  border-radius: 0.2rem;
}


thead tr{
  background-color: #3f87a6;
  color: #000000;
}
thead tr div{
  color: #ffffff;
  font-weight: bold;
}
tbody {
  text-align: left;
}
tbody tr:nth-child(odd) {
  background-color: #e4edf4 ;
}
tbody tr:nth-child(even) {
    background-color: #FFFFFF ;
}

tbody tr:hover {
    background-color:#04ff00 ;
}

#custom-select{
  width: 90px;
}
#custom-select2{
  width: 180px;
}

thead tr div #custom-filter  {
display: block;
width: 100%;
height: calc(1.5em + .75rem + 2px);
padding: .375rem .75rem;
font-size: 1rem;
font-weight: 400;
margin: 0 auto;
background-color: #ffffff;
line-height: 1.5;
color: black;
background-clip: padding-box;
border: 1px solid #ced4da;
border-radius: .25rem;
}

thead tr div #custom-filter2  {
display: block;
width: 550px;
height: calc(1.5em + .75rem + 2px);
padding: .375rem .75rem;
font-size: 1rem;
font-weight: 400;
margin: 0 auto;
background-color: #ffffff;
line-height: 1.5;
color: black;
background-clip: padding-box;
border: 1px solid #ced4da;
border-radius: .25rem;
}
thead tr div #custom-filter3 {
display: block;
width: 180px;
height: calc(1.5em + .75rem + 2px);
padding: .375rem .75rem;
font-size: 1rem;
font-weight: 400;
margin: 0 auto;
background-color: #ffffff;
line-height: 1.5;
color: black;
background-clip: padding-box;
border: 1px solid #ced4da;
border-radius: .25rem;
}



[type="date"] {
background:#fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)  97% 50% no-repeat ;
content: attr(placeholder);

}
[type="date"]::-webkit-inner-spin-button {
display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
opacity: 0;
}

label {
display: block;
}
input {
border: 1px solid #c4c4c4;
border-radius: 5px;
background-color: #fff;
padding: 3px 5px;
box-shadow: inset 0 3px 6px rgba(0,0,0,0.1);
width: 190px;
}


body{
overflow-x:hidden;
}

.button {
  display: inline-block;
  border-radius: 4px;
  background-color: #3f87a6;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 12px;
  padding: 10px;
  width: 110px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  }
  .button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  }
  .button span:after {
  content: '\00bb';  /* Entidades CSS. Para usar entidades HTML, use &#8594;*/
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
  }
  
#exportarcsv{
float: left;
margin-top: 54px;


}
.filtroCancel{
width: 190px;
text-align: center;
}


.selectGeneral{
width: 500px;
}




* {
box-sizing: border-box;
}

body {
font-family: 'Roboto', sans-serif;
}
button {
background-color: #3f87a6;
border: 0;
border-radius: 5px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
color: #fff;
font-size: 14px;
padding: 10px 25px;
}

.cancel{
cursor: pointer;
width: 20px;
}

.modal-container {
display: flex;
background-color: rgba(0, 0, 0, 0.3);
align-items: center;
justify-content: center;
position: fixed;
pointer-events: none;
opacity: 0;  
top: 0;
left: 0;
height: 100vh;
width: 100vw;
transition: opacity 0.3s ease;

}

.show {
pointer-events: auto;
opacity: 1;
}

.modal {
background-color: rgb(173, 12, 12);
width: 600px;
max-width: 100%;
padding: 30px 50px;
border-radius: 5px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
text-align: center;
z-index: 10000000;
}

.modal h1 {
margin: 0;
}

.modal p {

font-size: 14px;
}

.motivo{
display: block;
text-align: center;
line-height: 150%;

}
.motivoCancel{
text-align: center;

}
.envCancel{

width: 100%;

}

.div1c { float: left; margin-top: 36px; padding-right: 20px;}
.div2c { float: left;margin-top: 36px; padding-right: 20px}
.div3c { float: left;margin-top: 36px; padding-right: 20px}
.div4c { float: left; margin-top: 36px; padding-right: 20px}
.div5c { float: left; margin-top: 36px; padding-right: 20px}


.buscarfacturas{
  margin-top: 50px;
  float: left;
  padding-left: 30px;
}
.buscarfacturasM01{
  float: left;
  padding-left: 60px;
}

.facturasConsultasTotales{
  float: left;
}


.Gfactura {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;  
  font-size: 2rem;
}


.GPago {
  font-weight: 500;
  line-height: 1.2;  
  padding-top:10px;
  font-size: 2rem;
  float: right;
}

.TablaConsultas{
width: 100%;
height: 100%;
}

.contenedorTable{
 width: 85%;
 margin: 0 30px;

}

.contenedorTableComp{

width: 90%;
height: 90%;
}



@media only screen and (max-width: 1400px)  {
.contenedorTable{
  width: 66% ;
  margin: 0 20px;

 }  
 

}
@media only screen and (max-width: 1390px)  {
.contenedorTable{
  width: 64% ;
  margin: 0 20px;

 }  
 
}
@media only screen and (max-width: 1280px)  {
.contenedorTable{
  width: 58% ;
  margin: 0 20px;

 }  

}

@media only screen and (max-width: 1100px)  {
.contenedorTable{
  width: 50% ;
  margin: 0 20px;

 }  

}

@media only screen and (max-width: 900px)  {
  .contenedorTable{
    width: 42% ;
    margin: 0 20px;
  
   }  
  
  }


@media only screen and (max-width: 800px)  {
  .contenedorTable{
    width: 37% ;
    margin: 0 20px;
  
   }  
  
  }



  

/*NUevo modal*/

.App {
  font-family: sans-serif;
  text-align: center;
}

#myImg {
  max-width: 300px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {
  opacity: 0.7;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
}
  .App {
    font-family: sans-serif;
    text-align: center;
  }

  #myImg {
    max-width: 300px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }

  #myImg:hover {
    opacity: 0.7;
  }

  /* The Modal (background) */
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
  }

  /* Modal Content (image) */
  .modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
  }

  /* Caption of Modal Image */
  #caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
  }

  /* Add Animation */
  .modal-content,
  #caption {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
  }

  @-webkit-keyframes zoom {
    from {
      -webkit-transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
    }
  }

  @keyframes zoom {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  /* The Close Button */
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }

  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }

  /* 100% Image Width on Smaller Screens */
  @media only screen and (max-width: 700px) {
    .modal-content {
      width: 100%;
    }
  }

  to {
    transform: scale(1);
  }


/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}




.botonConfirmar{
  background-color: green;
  margin: 5px;
}

.botonAbortar{
  background-color: red;
  margin: 5px;

}
.PreguntaConf{
  
  font-weight: bold;
  color: #000000;
}





figcaption {
  display:none; 
  transition: all .5s;
}
/* Visible texto */
figure:hover > figcaption {
  display:block;
  transition: all .5s;
}






.tableConsultas td:nth-child(12){
  text-align: center !important;
}

.tableConsultas td:nth-child(6){
  text-align: justify !important;
}



.tableRelacion td:nth-child(5){
  text-align: right;
}


.tableRelacion thead th:nth-child(5){
  width: 15%;
}

.tableRelacion thead td:nth-child(4){
  text-align: justify !important;
}





.tableConsultas td:nth-child(12){
  text-align: center !important;
}

.tableConsultas td:nth-child(6){
  text-align: justify !important;
}



.tableRelacion td:nth-child(5){
  text-align: right;
}


.tableRelacion thead th:nth-child(5){
  width: 15%;
}

.tableRelacion thead td:nth-child(4){
  text-align: justify !important;
}





@media only screen and (max-width: 1920px) {
  
  .cancel{
      cursor: pointer;
      width: 38%;
      height: 38%;
      
  }

}




@media only screen and (max-width: 1440px) {
 
  .cancel{
      cursor: pointer;
      width: 40% !important;
      height: 40% !important;
  
}
}

@media only screen and (max-width: 1280px) {


  .cancel{
      cursor: pointer;
      width: 48%;
      height: 48%;
      
  }


  }


  @media only screen and (max-width: 1152px) {
    
  
      .cancel{
          cursor: pointer;
          width: 48%;
          height: 48%;
          
      }
  
}


 


  @media only screen and (max-width: 1024px) {


  
      .cancel{
          cursor: pointer;
          width: 48%;
          height: 48%;
          
      }
  
}

@media only screen and (max-width: 830px) {


  .cancel{
      cursor: pointer;
      width: 48%;
      height: 48%;   
  }

  
}

.slectRelacionCancel{
  text-align: center;
}



.reportInvoice{
  width: 43px;
}