.TableCatalogoGastos .sticky-table-table{
    width: 100%;
}
.TableCatalogoGastos{
    margin: 0 auto;
    width: 95% !important;
}
.TableCatalogoGastos .titletable{
    margin-top: -30px;
    margin-bottom: -50px;
}
.TableCatalogoGastos td:nth-child(1){
  text-align: center;
  max-width: 85px;
  padding-right: 30px;
}

.TableCatalogoGastos td:nth-child(3){
    text-align: center;
}

.TableCatalogoGastos td:nth-child(2),
.TableCatalogoGastos td:nth-child(4){
    text-align: left;
}
.gridcatalogogastos{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 25% 20% 20% 25%;
    grid-template-areas:
    "a a a a";
    grid-gap: 10px;
}
.gridcatalogogastos2{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 25% 20% 20% 25%;
    grid-template-areas:
    "a b b b";
    grid-gap: 10px;
}
.gridcatalogogastos3{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 25% 20% 20% 25%;
    grid-template-areas:
    "a a b b";
    grid-gap: 10px;
}
.catalogo1{grid-area: a;}
.catalogo2{grid-area: a;}
.catalogo3{grid-area: b;}
.catalogo4{grid-area: a;}
.catalogo5{grid-area: b;}

.catalogo3b{
    grid-area: b;
    padding-left: 20px;
    padding-top: 12px;
    margin-right: -10px;
}
.catalogo3b label{
    font-size: 12px;
}
.TableCatalogoGastosConceptos .sticky-table-table{
    width: 100%;
}
.TableCatalogoGastosConceptos{
    margin: 0 auto;
    width: 95% !important;
}
.TableCatalogoGastosConceptos .titletable{
    margin-top: -40px;
    margin-bottom: 10px;
}
.TableCatalogoGastosConceptos td:nth-child(1){
  text-align: center;
  min-width: 80px;
  padding-right: 30px;
}
.TableCatalogoGastosConceptos td:nth-child(2),
.TableCatalogoGastosConceptos td:nth-child(4),
.TableCatalogoGastosConceptos td:nth-child(5){
    text-align: left;
}
.TableCatalogoGastosConceptos td:nth-child(3){
    text-align: center;
}

@media only screen and (max-width: 1080px) {
    .TableCatalogoGastosConceptos td:nth-child(1){
        min-width: 120px;
        padding-right: 30px;
    }
    .TableCatalogoGastos td:nth-child(1){
        min-width: 120px;
        padding-right: 30px;
    }
}

@media only screen and (max-width: 480px) {
    .gridcatalogogastos2{
        grid-template-areas:
        "a a a a"
        "b b b b";
        padding-bottom: 20px;
    }
    .catalogo3b{
       margin-left: -10px;
    }


    .gridcatalogogastos3{
        grid-template-areas:
        "a a a a"
        "b b b b";
    } 
}


.breadcrumb a{
    color: #1d1dff !important;
  }