/* .main-filter {
    height: 100%;
    background-color: #eff0f2;
    color: #287993;
  }
  
  form, .title {
      padding-left: 100px;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 15px;
  }
  
  .main-header__left>div,
  .main-header__right>div {
    display: flex;
  }
  
  .main-header__left>div>label,
  .main-header__right>div>label {
    width: 40%;
  }

  .filter-button1 {
    margin: 0;
    background: #38acd2;
    color: #FFFFFF;
    min-height: 35px;
    font-weight: bold;
    border: none;
    margin-top: 18px;
    width: 100%;
  }
  
  input, select{
    width: 100%;
    height: 30px;
    border: 1px solid #38acd2;
  }
  
  @media only screen and (max-width: 500px) {
   
    .main-header__right {
      grid-area: 2 / span 1;
      grid-gap: 0;
    }
  }





  * {
				margin:0px;
				padding:0px;
			}
			
			#header {
				margin:auto;
				width:100%;
				font-family:Arial, Helvetica, sans-serif;
			} */
      ul,
      ol {
        list-style: none;
      }
      
      .nav > li {
        float: left;
      }
      
      .nav li a {
        background: #1a374d;
        color: #fff;
        text-decoration: none;
        padding: 10px 12px;
        text-align: left;
        display: block;
        z-index: 12;
      }
      
      .nav li a:hover {
        background-color: #434343;
      }
      
      .nav li ul {
        display: none;
        position: absolute;
        min-width: 140px;
      }
      
      .nav li:hover > ul {
        display: block;
      }
      
      .nav li ul li {
        position: relative;
        z-index: 20000000000;
      }
      
      .nav li ul li ul {
        right: -280px;
        top: 0px;
        z-index: 20000000000;
      }
      
      /*socio de negocios termina*/
      
      .nav2 > li {
        float: left;
      }
      
      .nav2 li a {
        background: #1a374d;
        color: #fff;
        text-decoration: none;
        padding: 10px 12px;
        display: block;
      }
      
      .nav2 li a:hover {
        background-color: #434343;
      }
      
      .nav2 li ul {
        display: none;
        position: absolute;
        min-width: 340px;
      }
      
      .nav2 li:hover > ul {
        display: block;
      }
      
      .nav2 li ul li {
        position: relative;
        z-index: 20000000000;
      }
      
      .nav2 li ul li ul {
        right: -140px;
        top: 0px;
      }
      
      .containerfull {
        background: #1a374d;
      }
      
      .container2 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas: "logo logo menu buscador buscador . . . . usuario";
      }
      
      .menu {
        grid-area: menu;
        padding-top: 37px;
      }
      
      .buscador {
        grid-area: buscador;
        padding-top: 15px;
      }
      
      .usuario {
        grid-area: usuario;
        padding-top: 27px;
      }
      .encabezadoA {
        color: #fff !important;
      }
      
      .logo {
        grid-area: logo;
      }
      .logo-img {
        width: 70px !important;
        height: 51px !important;
      }
      #tc {
        position: absolute;
        padding-top: 10px;
        top: 85px;
        left: 60px;
        right: 20px;
        bottom: 0%;
        width: calc(100% - 80px);
        height: calc(100% - 60px);
      }
      .paraajustes {
        position: absolute;
        display: none;
        grid-template-areas:
          "ajustes"
          "cuenta"
          "close"
          "for-icons";
        top: 50px;
        width: 50%;
        height: 350px;
        background-color: #1a374d;
        left: 30%;
        right: 10%;
        overflow-y: auto;
        font-size: large;
        font-weight: bold;
        align-items: center;
        border-radius: 0% 0% 15px 15px;
        text-align: justify;
      }
      
      .ajustes {
        grid-area: ajustes;
        color: white;
        width: 100%;
        height: 100%;
        display: block;
      }
      .cuenta {
        grid-area: cuenta;
        color: white;
        width: 100%;
        height: 100%;
        display: block;
      }
      
      .close-sesion {
        grid-area: close;
        color: white;
        width: 100%;
        height: 100%;
        display: block;
      }
      
      .user-active {
        display: grid;
        grid-template-areas: "email avatar";
        grid-template-columns: 80% 20%;
        width: 30%;
        top: 0%;
        right: 0%;
        height: 40px;
        position: absolute;
      }
      
      .user-email {
        grid-area: email;
        width: 90%;
        padding: 15px;
        color: white;
      }
      .user-avatar {
        grid-area: avatar;
        width: 100%;
        padding: 2px;
      }
      
      
      .new-menu div span a{
      
        color: #fff !important;
      }
      
      
      #scrollbar::-webkit-scrollbar-track {
        background-color: #de0202;
      }
      
      .favOD2{
        font-size: 12px !important;
      }