.create_movements {
  width: 90%;
  margin: 0 auto;
}
.mv-5 {
  grid-area: mv5;
}
.mv-6 {
  grid-area: mv6;
}
.mv-7 {
  grid-area: mv7;
}

.mv-8 {
  grid-area: mv8;
}



.create_movements {
  display: grid;
  grid-template-areas: "mv5 mv6"
                       "mv7 mv8";
  gap: 10px;
}

input.inputDataEdit{
  width: 100px !important;
}



.containerMovements {
  width: 95%;
  margin: 0 auto;
  padding-right: 4%;
}


.pdc-1{
  grid-area: pd1;
}
.pdc-2{
  grid-area: pd2;
}
.pdc-3{
  grid-area: pd3;
  padding-top: 12px;
}
.pdc-4{
  grid-area: pd4;
  padding-top: 12px;

}

.containerMovements {
  display: grid;
  grid-template-areas:         
                  "pd1 pd2 pd3 pd4";
  gap: 10px;
}

.existProduct{
  padding-top: 12px;
}

.containerStock {
  width: 95%;
  margin: 0 auto;
  padding-left: 1%;
}
.containerStock {
  display: grid;
  position: absolute;
  grid-template-areas:         
                  "pd1 pd2 pd3 pd4";
  gap: 10px;
}

.dt-1{
  grid-area: dt1;
}
.dt-2{
  grid-area: dt2;
}
.dt-3{
  grid-area: dt3;
}
.dt-4{
  grid-area: dt4;
}
.dt-5{
  grid-area: dt5;
}
.dt-6{
  grid-area: dt6;
}



.containerDetail {
  width: 95%;
  margin: 0 auto;
  padding-left: 1%;
}
.containerDetail {
  display: grid;
  position: relative;
  grid-template-areas:         
                  "dt1 dt2 dt3 dt4"
                  "dt5 dt5 dt6 dt7";
  gap: 10px;
}