@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }


  .contenedor{
  float: left;
  }
  


  .contenerFormSetBusnes{
    border-top: 0px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-left:   1px solid;
  width: 95%;
  margin: 0 auto;
  }


  .btonwith{
    width: 1000px !important;
  }
 

  /*containerFactureResponse-1 fact-1... fact-5 */
.containerBussnessp {
  width: 90%;
  margin: 0 auto;
  padding-right: 4%;
}
.bp-1 {
  grid-area: cp1;
}
.bp-2 {
  grid-area: cp2;
}
.bp-3 {
  grid-area: cp3;
}
.bp-4 {
  grid-area: cp4;
}
.bp-5 {
  grid-area: cp5;
}
.bp-6 {
  grid-area: cp6;
}
.bp-7 {
  text-align: center;
  grid-area: cp7;
}

.bp-8 {
  grid-area: cp8;
}
.bp-9 {
  grid-area: cp9;
}
.bp-10 {
  grid-area: cp10;
}
.bp-11 {
  grid-area: cp11;
}
.bp-12 {
  grid-area: cp12;
}
.bp-13 {
  grid-area: cp13;
}
.bp-14 {
  grid-area: cp14;
}
.bp-15 {
  grid-area: cp15;
}
.bp-16 {
  grid-area: cp16;
}
.bp-17 {
  grid-area: cp17;
}
.bp-18 {
  grid-area: cp18;
}
.bp-19 {
  grid-area: cp19;
}
.bp-20 {
  grid-area: cp20;
}

.bp-21 {
  grid-area: cp21;
}

.bp-22 {
  grid-area: cp22;
}

.bp-23 {
  grid-area: cp23;
}

.bp-24 {
  grid-area: cp24;
}
.bp-25 {
  grid-area: cp25;
  text-align: center;
}

.containerBussnessp {
  display: grid;
  grid-template-areas: "cp1 cp7 cp2 cp2 cp2 cp3"
                       "cp4 cp4 cp5 cp5 cp6 cp6"
                       "cp8 cp8 cp9 cp9 cp10 cp10"
                       "cp11 cp11 cp12 cp12 cp13 cp14"
                       "cp15 cp15 cp16 cp16 cp17 cp17"
                       "cp18 cp18 cp19 cp19 cp20 cp20"
                       "cp25 cp25 cp25 cp25 cp25 cp25"
                       "cp23 cp23 cp23 cp24 cp24 cp24"
                       "cp21 cp21 cp21 cp22 cp22 cp22";

  gap: 10px;
}

.contenerFormSetBusnesAdress {
  width: 90%;
  margin: 0 auto;
  padding-right: 4%;
}
.contenerFormSetBusnesAdressUpdate{
  width: 90%;
  margin: 0 auto;
  padding-right: 4%;
}
.dr-1 {
  grid-area: dr1;
}
.dr-2 {
  grid-area: dr2;
}
.dr-3 {
  grid-area: dr3;
}
.dr-4 {
  grid-area: dr4;
}
.dr-5 {
  grid-area: dr5;
}
.dr-6 {
  grid-area: dr6;
}
.dr-7 {
  grid-area: dr7;
}

.dr-8 {
  grid-area: dr8;
}
.dr-9 {
  grid-area: dr9;
}
.dr-10 {
  grid-area: dr10;
}
.dr-11 {
  grid-area: dr11;
}
.dr-12 {
  grid-area: dr12;
}
.dr-13 {
  grid-area: dr13;
}
.dr-14 {
  grid-area: dr14;
}
.dr-15 {
  grid-area: dr15;
}

.contenerFormSetBusnesAdress{
  display: grid;
  grid-template-areas: "dr1 dr1 dr2 dr2 dr3 dr3"
                       "dr4 dr4 dr5 dr5 dr6 dr6"
                       "dr7 dr7 dr8 dr8 dr9 dr9"
                       "dr10 dr10 dr10 dr10 dr11 dr12"
                       "dr13 dr13 dr13 dr14 dr14 dr14";        
  gap: 10px;

}

.contenerFormSetBusnesAdressUpdate{
  display: grid;
  grid-template-areas: "dr1 dr1 dr2 dr2 dr3 dr3"
                       "dr4 dr4 dr5 dr5 dr6 dr6"
                       "dr7 dr7 dr8 dr8 dr9 dr9"
                       "dr10 dr10 dr10 dr10 dr11 dr12"
                       "dr15 dr15 dr13 dr13 dr14 dr14";        
  gap: 10px;
}



.importanMax{
  padding-left: 8%;
  margin: 0 auto;
  width: 90%;
}


.ResponseCodeQR{
  text-align: justify;
}