
/* Table de cuentas contables */
.TableAccounting{
    margin: 0 auto;
    width: 95% !important;
  }
  .TableAccounting .sticky-table-table{
    width: 100%;
  }
  .TableAccounting .titletable{
    margin-top: -30px;
    margin-bottom: -30px;
  }
  .TableAccounting td:nth-child(1){
    min-width: 100px;
    max-width: 120px;
    text-align: center;
  }
  .TableAccounting td:nth-child(2){
    text-align: center;
  }
  .TableAccounting td:nth-child(3),
  .TableAccounting td:nth-child(4){
    text-align: left;
  }
  .TableAccounting td:nth-child(5),
  .TableAccounting td:nth-child(6){
    text-align: center;
  }
  
  .gridcc{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 25% 20% 20% 25%;
    grid-template-areas:
        "a a b b";
    grid-gap: 10px;
  }
  .cc1{grid-area: a;}
  .cc2{grid-area: b;}
  
  .gridcc2{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 25% 20% 20% 25%;
    grid-template-areas:
        "a a a a";
    grid-gap: 10px;
  }
  .cc3{grid-area: a;}
  .gridcc3{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 25% 20% 20% 25%;
    grid-template-areas:"a a b b";
    grid-gap: 10px;
  }
  .cc4{grid-area: a;}
  .cc5{grid-area: b;}
  

  @media only screen and (max-width: 480px) {
    .gridcc{
       grid-template-areas:
            "a a a a"
            "b b b b";
      }
    .gridcc3{
        grid-template-areas:
            "a a a a"
            "b b b b";
    }

}
  
  
  