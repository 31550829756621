/*containerPagoResponse pag-1... fact-3 */
.containerPagoResponse {
  width: 90%;
  margin: 0 auto;
}
.pag-1 {
  grid-area: pg1;
}
.pag-2 {
  grid-area: pg2;
}
.pag-3 {
  grid-area: pg3;
}

.containerPagoResponse {
  display: grid;
  grid-template-areas: "pg1 pg2 pg2 pg3 pg3";
  gap: 10px;
}

/*containerPagoResponse-1 pag-4 y pag-5 */
.containerPagoResponse-1 {
  width: 90%;
  margin: 0 auto;
}
.pag-4 {
  grid-area: pg4;
  width: 100%;
  margin-top: 15px;
  padding-left: 2%;
}
.pag-5 {
  grid-area: pg5;
}

.containerPagoResponse-1 {
  display: grid;
  grid-template-areas: "pg4 pg4 pg4 pg4 pg5";
  gap: 10px;
}

/*containerPagoResponse-2 pag-6 y pag-9 */
.containerPagoResponse-2 {
  width: 90%;
  margin: 0 auto;
}
.pag-6 {
  grid-area: pg6;
}
.pag-7 {
  grid-area: pg7;
}

.pag-8 {
  grid-area: pg8;
}
.pag-9 {
  grid-area: pg9;
}

.fiPago {
  width: 100%;
  margin-top: 10px;
  height: 39px;
  text-align: center;
  margin-left: 10px;
}

.containerPagoResponse-2 {
  display: grid;
  grid-template-areas: "pg6 pg6 pg7 pg8 pg9";
  gap: 10px;
}

/*containerPagoResponse-3 pag-10 y pag-13 */
.containerPagoResponse-3 {
  width: 90%;
  margin: 0 auto;
}
.pag-10 {
  grid-area: pg10;
}

.pag-11 {
  grid-area: pg11;
}

.pag-12 {
  grid-area: pg12;
}

.pag-13 {
  grid-area: pg13;
}

.pag-14 {
  grid-area: pg14;
}

.containerPagoResponse-3 {
  display: grid;
  grid-template-areas: "pg10 pg11 pg12 pg13";
  gap: 10px;
}

/*containerPagoResponse-4 pag-14*/

.containerPagoResponse-4 {
  width: 90%;
  margin: 0 auto;
}
.pag-14 {
  grid-area: pg14;
}
.containerPagoResponse-4 {
  display: grid;
  grid-template-areas: "pg14";
  gap: 10px;
}

/*containerPagoResponse-4 pag-15 y pag16*/

.containerPagoResponse-5 {
  width: 90%;
  margin: 0 auto;
}
.pag-15 {
  grid-area: pg15;
}
.pag-16 {
  grid-area: pg16;
}
.containerPagoResponse-5 {
  display: grid;
  grid-template-areas: "pg15 pg16 pg17";
  gap: 10px;
}

/*containerPagoResponse-6 pag-17*/

.containerPagoResponse-6 {
  width: 90%;
  margin: 0 auto;
}
.pag-17 {
  grid-area: pg17;
}
.containerPagoResponse-6 {
  display: grid;
  grid-template-areas: "pg17";
  gap: 10px;
}

/*containerPagoResponse-7 pag-18...pag20*/

.containerPagoResponse-7 {
  width: 90%;
  margin: 0 auto;
}
.pag-18 {
  grid-area: pg18;
  width: 100%;
  margin-top: 15px;
  padding-left: 5%;
}

.pag-19 {
  grid-area: pg19;
}
.pag-20 {
  grid-area: pg20;
}
.containerPagoResponse-7 {
  display: grid;
  grid-template-areas: "pg18 pg19 pg20";
  gap: 10px;
}

/*containerPagoResponse-8 pag-21...pag22*/

.containerPagoResponse-8 {
  width: 90%;
  margin: 0 auto;
}
.pag-21 {
  grid-area: pg21;
  width: 100%;
  margin-top: 15px;
  padding-left: 2%;
}

.pag-22 {
  grid-area: pg22;
}

.containerPagoResponse-8 {
  display: grid;
  grid-template-areas: "pg21 pg22";
  gap: 10px;
}

/*containerPagoResponse-9 pag-23...pag24*/

.containerPagoResponse-9 {
  width: 90%;
  margin: 0 auto;
}
.pag-23 {
  grid-area: pg23;
}

.pag-24 {
  grid-area: pg24;
}

.containerPagoResponse-9 {
  display: grid;
  grid-template-areas: "pg23 pg24";
  gap: 10px;
}

.mensajeErroCuOrd {
  color: red;
}

.table_payment {
  width: 95% !important;
  margin: 0 auto !important;
}
