
.TableRutas .sticky-table-table{
    width: 100%;
  }
  .TableRutas{
    margin: 0 auto;
    width: 95% !important;
  }
  .TableRutas .titletable{
    margin-top: -30px;
    margin-bottom: -50px;
  }

.TableRutas td:nth-child(1){
  text-align: center;
  min-width: 100px;
}
.TableRutas td:nth-child(2),
.TableRutas td:nth-child(3),
.TableRutas td:nth-child(4){
  text-align: center;
}
.TableRutas td:nth-child(5){
  text-align: left;
}
.TableRutas td:nth-child(6){
  text-align: center;
}
.TableRutas td:nth-child(7),
.TableRutas td:nth-child(8){
  text-align: left;
}
.TableRutas td:nth-child(9),
.TableRutas td:nth-child(10){
  text-align: center;
}



.gridroutes{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 20% 20% 7% 7% 20% 20%;
    grid-template-areas:
    "a b b b b c";
    grid-gap:10px;
}
.gr1{grid-area: a;}
.gr2{grid-area: b;}
.gr3{grid-area: c;}

.gridroutes2{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 20% 20% 7% 7% 20% 20%;
  grid-template-areas:
  "a a a b b b";
  grid-gap:10px;
}
.gr4{grid-area: a;}
.gr5{grid-area: b;}

.gridroutes3{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 20% 20% 7% 7% 20% 20%;
  grid-template-areas:
  "a a a b b b";
  grid-gap: 10px;
}
.gr6{grid-area: a;}
.gr7{grid-area: b;}


.gridroutes4{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 20% 7% 20% 7% 20% 20%;
  grid-template-areas:
  "a a b b c c";
  grid-gap: 10px;
}
.gr8{grid-area: a;}
.gr9{grid-area: b;}
.gr10{grid-area: c;}

.gridroutes5{
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 20% 20% 7% 7% 20% 20%;
  grid-template-areas:
  "a a a b b b";
  grid-gap:10px;
}
.gr11{grid-area: a;}
.gr12{grid-area: b;}


@media only screen and (max-width: 720px) {

  
.gridroutes{
  grid-template-areas:
  "a a a c c c"
  "b b b b b b";
 }
 .gridroutes2{
  grid-template-areas:
  "a a a a a a"
  "b b b b b b";
 }
 .gridroutes3{
  grid-template-areas:
  "a a a a a a"
  "b b b b b b"
 }
 .gridroutes4{
  grid-template-areas:
  "a a a a a a"
  "b b b c c c";
 }
}

@media only screen and (max-width: 480px) {

  
  .gridroutes{
    grid-template-areas:
    "a a a a a a"
    "b b b b b b"
    "c c c c c c";
   }
   .gridroutes2{
    grid-template-areas:
    "a a a a a a"
    "b b b b b b";
   }
   .gridroutes3{
    grid-template-areas:
    "a a a a a a"
    "b b b b b b"
   }
   .gridroutes4{
    grid-template-areas:
    "a a a a a a"
    "b b b b b b"
    "c c c c c c";
   }
   .gridroutes5{
    grid-template-areas:
    "a a a a a a"
    "b b b b b b"
  }
  }