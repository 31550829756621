.TableSemaphore .sticky-table-table{
    width: 100%;
}
.TableSemaphore{
    margin: 0 auto;
    width: 90% !important;
}
.TableSemaphore .titletable{
    margin-bottom: -40px;
}

.TableSemaphore td:nth-child(1){
    width: 100px;
    text-align: center;
    
}
.TableSemaphore td:nth-child(1) img{
    width: 30px;
    height: 30px;
}
.TableSemaphore td:nth-child(2){
    text-align: center;
    width: 60px;
}
.TableSemaphore td:nth-child(3){
    text-align: right;
}

.TableSemaphore td:nth-child(4){
    text-align: right;
}

.grid-semaphore{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 12% 40% 40%;
    grid-template-areas: "a b c ";
    grid-gap: 10px;
}
.semaphore1{
    grid-area: a;
}
.semaphore2{
    grid-area: b;
}
.semaphore3{
   grid-area: c;
}

.semaphore1 input{
    height: 30px;
    padding: 10px;
}

.semaforo-celda{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

@media only screen and (max-width: 480px) {
    .grid-goal{
        grid-template-areas: 
        "a a"
        "b b";
    }
    
  }