
.TableReportSaleRoute .tableg .sticky-table-table{
    width: 100%;
  }
.TableReportSaleRoute .tableg{
  margin: 0 auto;
  width: 95% !important;
}
.tableg{
  margin-top: -65px !important;
}
.tableg td:nth-child(1){
  text-align: center;
}
.tableg td:nth-child(2),
.tableg td:nth-child(3){
  max-width: 200px;
  white-space: normal !important;
  word-wrap: break; 
  text-align: left;
}

.tableg td:nth-child(4),
.tableg td:nth-child(5),
.tableg td:nth-child(6),
.tableg td:nth-child(7),
.tableg td:nth-child(8),
.tableg td:nth-child(9),
.tableg td:nth-child(10),
.tableg td:nth-child(11),
.tableg td:nth-child(12),
.tableg td:nth-child(13){
  text-align: right;
  max-width: 100px;
}

.tableg td:nth-child(3),
.tableg td:nth-child(4){
  max-width: 120px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}


.tableg th:nth-child(11), .tableg th:nth-child(12){
  max-width: 120px;
  white-space: normal !important;
  word-wrap: break;
}

.TableReportSaleRoute .tableg2 .sticky-table-table{
  width: 100%;
}
.TableReportSaleRoute .tableg2{
margin: 0 auto;
width: 90% !important;
}
.tableg2{
  margin-top: -65px !important;
}

.tableg2 :nth-child(1){
  text-align: center;
}

.tableg2 :nth-child(2),
.tableg2 :nth-child(3),
.tableg2 :nth-child(4),
.tableg2 :nth-child(5){
  text-align: right;
}


.gridfiltros{
  margin: 0 auto;
  display: grid;
  align-items: flex-end;
  width: 95%;
  grid-template-columns: 15% 15% 15% 40%;
  grid-template-areas:
  "a b c d";
  grid-gap:10px;
  padding-bottom: 10px;
  position: relative;
  z-index: 10;
}
.fil1{grid-area: a;}
.fil2{grid-area: b;}
.fil3{grid-area: c;}

.fil4{grid-area: d;}

.gridfiltros label{
  font-size: 17px;
}
.gridfiltros .fil1, .gridfiltros .fil2, .gridfiltros .fil4{
  margin-bottom: 15px;
}
.gridfiltros .fil4{
  text-align: left;
  padding-left: 30px;
}



.gridfiltros .fil1 .fi,.gridfiltros .fil2 .ff{
  width: 90% !important;
  margin-top: 15px;
  font-size: 17px;
  height: 50px;
  color: black;
}

.filtrosgraphic{
  margin: 0 auto;
  display: grid;
  width: 95%;
  grid-template-columns: 15% 16% 15% 12% 17% 12% 10% 10%;
  grid-template-areas:
  "a b c d e f g h";
}
.fg1{grid-area: a;}
.fg2{grid-area: b;}
.fg3{grid-area: c;}
.fg4{grid-area: d;}
.fg5{grid-area: e;}
.fg6{grid-area: f;}
.fg7{grid-area: g;
text-align: right;
}

.fg7 p{
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px;
}



.details1{
  margin: 0 auto;
  display: grid;
  width: 95%;
  grid-template-columns: 12% 10% 12% 10% 12% 10% 12% 12%;
  grid-template-areas:
  "a a b b b c c c";
  grid-gap:10px;
}
.details2, .details3{
  margin: 0 auto;
  display: grid;
  width: 95%;
  grid-template-columns: 12% 10% 12% 10% 12% 10% 12% 12%;
  grid-template-areas:
  "a a b b c c d d";
  grid-gap:10px;
}
.details4{
  margin: 0 auto;
  display: grid;
  width: 95%;
  grid-template-columns: 12% 10% 12% 10% 12% 10% 12% 12%;
  grid-template-areas:
  "a a b b c c d d";
  grid-gap:10px;
}
.det1{grid-area: a;}
.det2{grid-area: b;}
.det3{grid-area: c;}

.det4{grid-area: a;}
.det5{grid-area: b;}
.det6{grid-area: c;}
.det7{grid-area: d;}

.det8{grid-area: a;}
.det9{grid-area: b;}
.det10{grid-area: c;}
.det11{grid-area: d;}

.det12{grid-area: a;}
.det13{grid-area: b;}
.det14{grid-area: c;}


.containerExport{
  position: relative;
  Z-index: 10;
}
.filtrosgraphic label{
  font-size: 17px;
}

.filtrosgraphic p{
  font-size: 17px;
  font-weight: bold;
  text-align: center;
}


.detalles_grafic .sticky-table-table{
  width: 100%;
}
.detalles_grafic{
  margin: 0 auto;
  width: 90% !important;
}
.detalles_grafic td:nth-child(1){
  text-align: center;
}
.detalles_grafic td:nth-child(2){
  text-align: left;
  max-width: 100px;
  white-space: normal !important;  
}
.detalles_grafic td:nth-child(3),
.detalles_grafic td:nth-child(4),
.detalles_grafic td:nth-child(5),
.detalles_grafic td:nth-child(6),
.detalles_grafic td:nth-child(7),
.detalles_grafic td:nth-child(8){
  text-align: right;
}

.detalles_grafic{
  margin-top: -50px;
}
.detalles_grafic .tableFixHeadDashboard{
  height: 500px !important;
}


@media only screen and (max-width: 1080px) {
  .filtrosgraphic{
    grid-template-areas:
    "a a b b c c"
    "d d e e f f"
    "g g h h h h"
  }
  .gridfiltros{
    grid-template-areas:
    "a a c c"
    "b b d d";
   }

}

@media only screen and (max-width: 520px) {
  .filtrosgraphic{
    grid-template-areas:
    "a a a a a a"
    "b b b b b b"
    "c c c c c c"
    "d d d d d d"
    "e e e e e e"
    "f f f f f f";
  }
  .gridfiltros{
    grid-template-areas:
    "a a a a"
    "b b b b"
    "c c c c"
    "d d d d"
  }
}


/* checbox de metas */
.check-input-meta{
  width: 80%;
  margin: 0 auto;
}